// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#divPrincipal{
    background-color:#A9596E;
    min-height: 100%;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

.formatImg{
    width: 100%;
    height: 50%;
}

#navPrincipal{
    background-color: #F1B8C7;
}

a{
    color: #79404F;
}

h5{
    color: rgb(138, 0, 0);
}

p{
    color: rgb(255, 251, 44);
}

a.navbar-brand{
    font-family: 'Permanent Marker', cursive;
}

.nav-link:hover{
    font-weight: bold;
    color: #F10F49;
}

footer div{
    float: right;
}`, "",{"version":3,"sources":["webpack://./src/app/home/home.component.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,WAAW;IACX,yCAAyC;AAC7C;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["#divPrincipal{\n    background-color:#A9596E;\n    min-height: 100%;\n    width: 100%;\n    font-family: Arial, Helvetica, sans-serif;\n}\n\n.formatImg{\n    width: 100%;\n    height: 50%;\n}\n\n#navPrincipal{\n    background-color: #F1B8C7;\n}\n\na{\n    color: #79404F;\n}\n\nh5{\n    color: rgb(138, 0, 0);\n}\n\np{\n    color: rgb(255, 251, 44);\n}\n\na.navbar-brand{\n    font-family: 'Permanent Marker', cursive;\n}\n\n.nav-link:hover{\n    font-weight: bold;\n    color: #F10F49;\n}\n\nfooter div{\n    float: right;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
