// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#divPrincipal{
    text-align:center;
    background-color:#A9596E;
    min-height: 100%;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

h1{
    color: #FFFF;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    font-size: 4rem;
}

hr {
    border-top: 3px solid #F1B8C7;;
    width: 10%;
}

#divPlanilha{
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

td{
    font-size: 2rem;
}

table{
    background-color : #F1B8C7
}`, "",{"version":3,"sources":["webpack://./src/app/relatorio-alimentacao/relatorio-alimentacao.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,wBAAwB;IACxB,gBAAgB;IAChB,WAAW;IACX,yCAAyC;AAC7C;;AAEA;IACI,YAAY;IACZ,iCAAiC;IACjC,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;AACJ","sourcesContent":["#divPrincipal{\n    text-align:center;\n    background-color:#A9596E;\n    min-height: 100%;\n    width: 100%;\n    font-family: Arial, Helvetica, sans-serif;\n}\n\nh1{\n    color: #FFFF;\n    font-family: 'Oswald', sans-serif;\n    font-weight: bold;\n    font-size: 4rem;\n}\n\nhr {\n    border-top: 3px solid #F1B8C7;;\n    width: 10%;\n}\n\n#divPlanilha{\n    margin-left: 1.5rem;\n    margin-right: 1.5rem;\n}\n\ntd{\n    font-size: 2rem;\n}\n\ntable{\n    background-color : #F1B8C7\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
