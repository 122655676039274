// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#divPrincipal{
    text-align:center;
    background-color:#A9596E;
    min-height: 100%;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

#cardPrincipal{
    border-width: 2px;
    width: 23rem;
    left: 50%;
    margin-left: -11.5rem;
    background-color: rgba(230, 230, 230, 0.76);
}

h1{
    font-family: 'Permanent Marker', cursive;
    font-size: 5rem;
}

label{
    font-weight: bold;
    color: #A9596E;
}

#btnSalvar{
    background-color: #79404F;
    color: #FFFFFF;
    text-align: center;
    width: 5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,wBAAwB;IACxB,gBAAgB;IAChB,WAAW;IACX,yCAAyC;AAC7C;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,SAAS;IACT,qBAAqB;IACrB,2CAA2C;AAC/C;;AAEA;IACI,wCAAwC;IACxC,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,WAAW;AACf","sourcesContent":["#divPrincipal{\n    text-align:center;\n    background-color:#A9596E;\n    min-height: 100%;\n    width: 100%;\n    font-family: Arial, Helvetica, sans-serif;\n}\n\n#cardPrincipal{\n    border-width: 2px;\n    width: 23rem;\n    left: 50%;\n    margin-left: -11.5rem;\n    background-color: rgba(230, 230, 230, 0.76);\n}\n\nh1{\n    font-family: 'Permanent Marker', cursive;\n    font-size: 5rem;\n}\n\nlabel{\n    font-weight: bold;\n    color: #A9596E;\n}\n\n#btnSalvar{\n    background-color: #79404F;\n    color: #FFFFFF;\n    text-align: center;\n    width: 5rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
