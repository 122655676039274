// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `b, h5{
    color: #79404F;
}

#navPrincipal{
    width: 50%;
    margin-left: 25%;
    text-align: center;
    background-color: #F1B8C7;
    border-radius: 10px;
}

a{
    color: #79404F;
}

.nav-link:hover{
    font-weight: bold;
    color: #F10F49;
}
`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":["b, h5{\n    color: #79404F;\n}\n\n#navPrincipal{\n    width: 50%;\n    margin-left: 25%;\n    text-align: center;\n    background-color: #F1B8C7;\n    border-radius: 10px;\n}\n\na{\n    color: #79404F;\n}\n\n.nav-link:hover{\n    font-weight: bold;\n    color: #F10F49;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
