// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#divPrincipal{
    background-color:#A9596E;
    min-height: 100%;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

label{
    color: black;
}

input[type="text"], input[type="email"], input[type="password"] {
    color: black;
    background-color : #F1B8C7;
}

#divSalvar{
	position: absolute;
	width: 5rem;
	left: 50%;
	margin-left: -2.5rem;
}

#btnSalvar{
    background-color: #79404F;
    color: #FFFFFF;
    text-align: center;
    width: 5rem;
}

#divForms{
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/app/cadastro-dono/cadastro-dono.component.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,WAAW;IACX,yCAAyC;AAC7C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,0BAA0B;AAC9B;;AAEA;CACC,kBAAkB;CAClB,WAAW;CACX,SAAS;CACT,oBAAoB;AACrB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":["#divPrincipal{\n    background-color:#A9596E;\n    min-height: 100%;\n    width: 100%;\n    font-family: Arial, Helvetica, sans-serif;\n}\n\nlabel{\n    color: black;\n}\n\ninput[type=\"text\"], input[type=\"email\"], input[type=\"password\"] {\n    color: black;\n    background-color : #F1B8C7;\n}\n\n#divSalvar{\n\tposition: absolute;\n\twidth: 5rem;\n\tleft: 50%;\n\tmargin-left: -2.5rem;\n}\n\n#btnSalvar{\n    background-color: #79404F;\n    color: #FFFFFF;\n    text-align: center;\n    width: 5rem;\n}\n\n#divForms{\n    padding-left: 2rem;\n    padding-right: 2rem;\n    padding-top: 2rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
