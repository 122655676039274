// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#divPrincipal{
    text-align:center;
    background-color:#A9596E;
    min-height: 100%;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

#divImg{
    top: 0;
    background-color:#A9596E;
    width: 100%;
    margin-bottom: 0.5rem;
}

#imgPerfil{
    border-radius: 50%;
    width: 7rem;
    height: 7rem;
}

#cardPrincipal{
    width: 26rem;
    left: 50%;
    margin-left: -13rem;
}

h2{
    color: #FFFF;
    font-family: 'Dosis', sans-serif;
	font-size: 3rem;
}

.divPet{
    margin-right: 0.75rem;
}

#imgPet{
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
}

a{
    color: #FFFFFF;
}

a:hover, b, h5 {
    color: #79404F;
}`, "",{"version":3,"sources":["webpack://./src/app/perfil-dono/perfil-dono.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,wBAAwB;IACxB,gBAAgB;IAChB,WAAW;IACX,yCAAyC;AAC7C;;AAEA;IACI,MAAM;IACN,wBAAwB;IACxB,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,gCAAgC;CACnC,eAAe;AAChB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["#divPrincipal{\n    text-align:center;\n    background-color:#A9596E;\n    min-height: 100%;\n    width: 100%;\n    font-family: Arial, Helvetica, sans-serif;\n}\n\n#divImg{\n    top: 0;\n    background-color:#A9596E;\n    width: 100%;\n    margin-bottom: 0.5rem;\n}\n\n#imgPerfil{\n    border-radius: 50%;\n    width: 7rem;\n    height: 7rem;\n}\n\n#cardPrincipal{\n    width: 26rem;\n    left: 50%;\n    margin-left: -13rem;\n}\n\nh2{\n    color: #FFFF;\n    font-family: 'Dosis', sans-serif;\n\tfont-size: 3rem;\n}\n\n.divPet{\n    margin-right: 0.75rem;\n}\n\n#imgPet{\n    border-radius: 50%;\n    width: 5rem;\n    height: 5rem;\n}\n\na{\n    color: #FFFFFF;\n}\n\na:hover, b, h5 {\n    color: #79404F;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
