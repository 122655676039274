// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#cardPrincipal{
    border-width: 2px;
    width: 17.15rem;
    left: 50%;
    margin-left: -8.57rem;
}

#divPrincipal{
    text-align:center;
    background-color:#A9596E;
    min-height: 100%;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

#imgPrincipal{
    height:22.5rem;
    width:16.87rem;
}

h1{
    color: #FFFF;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
}
    
b, h5{
    color: #79404F;
}

hr {
    border-top: 3px solid #F1B8C7;;
    width: 10%;
}

.regular-checkbox {
    appearance: none;
	-webkit-appearance: none;
	background-color: #A9596E;
	border: 1px solid #FFFFFF;
	/* box-shadow: 0 3px 5px rgba(167, 40, 40, 0.521), inset 0px -15px 10px -12px rgba(0,0,0,0.05); */
	padding: 1.5rem;
	border-radius: 30px;
	display: inline-block;
	position: relative;
}

.regular-checkbox:active, .regular-checkbox:checked:active {
	box-shadow: 0 3px 5px rgba(167, 40, 40, 0.521), inset 0px 1px 3px rgba(0,0,0,0.1);
}

.regular-checkbox:checked {
	color: #FFFFFF;
}

.regular-checkbox:checked:after {
	content: '\\2714';
	font-size: 2.5rem;
	position: absolute;
	bottom: 0.1rem;
	margin-bottom: -0.5rem;
	left: 0.75rem;
	color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/app/animais/animais.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe;IACf,SAAS;IACT,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,wBAAwB;IACxB,gBAAgB;IAChB,WAAW;IACX,yCAAyC;AAC7C;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,iCAAiC;IACjC,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,6BAA6B;IAC7B,UAAU;AACd;;AAEA;IACI,gBAAgB;CACnB,wBAAwB;CACxB,yBAAyB;CACzB,yBAAyB;CACzB,iGAAiG;CACjG,eAAe;CACf,mBAAmB;CACnB,qBAAqB;CACrB,kBAAkB;AACnB;;AAEA;CACC,iFAAiF;AAClF;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,gBAAgB;CAChB,iBAAiB;CACjB,kBAAkB;CAClB,cAAc;CACd,sBAAsB;CACtB,aAAa;CACb,cAAc;AACf","sourcesContent":["#cardPrincipal{\n    border-width: 2px;\n    width: 17.15rem;\n    left: 50%;\n    margin-left: -8.57rem;\n}\n\n#divPrincipal{\n    text-align:center;\n    background-color:#A9596E;\n    min-height: 100%;\n    width: 100%;\n    font-family: Arial, Helvetica, sans-serif;\n}\n\n#imgPrincipal{\n    height:22.5rem;\n    width:16.87rem;\n}\n\nh1{\n    color: #FFFF;\n    font-family: 'Oswald', sans-serif;\n    font-weight: bold;\n}\n    \nb, h5{\n    color: #79404F;\n}\n\nhr {\n    border-top: 3px solid #F1B8C7;;\n    width: 10%;\n}\n\n.regular-checkbox {\n    appearance: none;\n\t-webkit-appearance: none;\n\tbackground-color: #A9596E;\n\tborder: 1px solid #FFFFFF;\n\t/* box-shadow: 0 3px 5px rgba(167, 40, 40, 0.521), inset 0px -15px 10px -12px rgba(0,0,0,0.05); */\n\tpadding: 1.5rem;\n\tborder-radius: 30px;\n\tdisplay: inline-block;\n\tposition: relative;\n}\n\n.regular-checkbox:active, .regular-checkbox:checked:active {\n\tbox-shadow: 0 3px 5px rgba(167, 40, 40, 0.521), inset 0px 1px 3px rgba(0,0,0,0.1);\n}\n\n.regular-checkbox:checked {\n\tcolor: #FFFFFF;\n}\n\n.regular-checkbox:checked:after {\n\tcontent: '\\2714';\n\tfont-size: 2.5rem;\n\tposition: absolute;\n\tbottom: 0.1rem;\n\tmargin-bottom: -0.5rem;\n\tleft: 0.75rem;\n\tcolor: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
